import { getMe } from 'api/user';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import { signInWithMagicLink } from '../api/auth';
import { Bots } from 'components/lists/lists/Bots';
import { Footer } from 'components/sections/Footer';
import { getAdditionalUserInfo } from 'firebase/auth';
import { fetchBillingSubscriptions } from 'api/billing';
import { reportAnalyticsEvent } from '../utils/analytics';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { getUA, isCrushAppMobile } from 'utils/userAgentCheck';

const AfterSubscriptionModal = dynamic(
  () => import('components/modals/AfterSubscriptionModal').then(({ AfterSubscriptionModal }) => AfterSubscriptionModal),
  { ssr: false }
);

const AfterCreditsPurchaseModal = dynamic(
  () => import('components/modals/AfterCreditsPurchaseModal').then(({ AfterCreditsPurchaseModal }) => AfterCreditsPurchaseModal),
  { ssr: false }
);

export const callApisRepeatedly = async () => {
  let count = 0;
  const intervalId = setInterval(async () => {
    count++;

    await fetchBillingSubscriptions();
    await getMe();

    if (count >= 4) {
      clearInterval(intervalId);
    }
  }, 3000);
};

export const getServerSideProps: GetServerSideProps = async ({ req, params }) => {
  return {
    props: { userID: req?.cookies?.userID || null }
  };
};

const HomePage = ({ userID }: { userID: string }) => {
  const { query, pathname, replace, push } = useRouter();
  const { mutate } = useMutation(signInWithMagicLink, {
    onSuccess: (userCredential) => {
      if (!userCredential) return;
      const additionalInfo = getAdditionalUserInfo(userCredential);

      if (additionalInfo?.isNewUser) {
        reportAnalyticsEvent('sign_up', { method: 'email' });
        push('/signup/success');
      } else {
        reportAnalyticsEvent('login', { method: 'email' });
        push('/', undefined, { shallow: true });
      }
    }
  });
  const isMobile = isCrushAppMobile();

  const closeModal = () => {
    replace({ pathname, query: {} }, undefined, { shallow: true });
    callApisRepeatedly();
  };

  useEffect(() => {
    if (query.apiKey && query.email) {
      mutate(query.email as string);
    }
  }, [query, mutate]);

  return (
    <>
      <div className="container">
        <div className="flex flex-col gap-8 md:gap-32">
          <Bots isLoggedSSR={Boolean(userID)} />
        </div>

        {!isMobile && (
          <div className="bg-secondary p-4 flex flex-col gap-3">
            <h2 className="text-2xl font-bold mb-1">
              Discover unmatched and uncensored <Link href="/" className="text-brand hover:underline">AI Sexting</Link> with your <Link href="/" className="text-brand hover:underline">AI Girlfriend</Link>
            </h2>
            <div>
              Let Krush be your guide on an exciting journey into the world of AI sexting. With our cutting-edge AI chatbot technology, you can engage in intimate conversations with your AI companion.
            </div>
            <div>
              At Krush, you can <Link href="/create" className="text-brand hover:underline">design your AI girlfriend</Link>&apos;s features and personality to match your preferences. Your unique AI girlfriend is ready to chat and connect with you in a way that feels natural and engaging.
            </div>
            <div>
              Through every interaction, your AI girlfriend gets to know you better. From light-hearted chit chat to more intense AI sexting moments or even NSFW erotic role play, just explore a variety of experiences with ease.
            </div>
            <div>
              Express your desires by requesting photos and selfies from your AI girlfriend, each presented with a consistent and captivating appearance thanks to our state-of-the-art AI girl image generator.
            </div>
            <div>
              Discover the perfect Krush AI companion and step into a world of digital intimacy like never before.
            </div>
            <div>
              Try <Link href="/" className="text-brand hover:underline">KrushChat AI</Link> for Free Now!
            </div>
          </div>
        )}

        {query?.credits && (
          <AfterCreditsPurchaseModal onClose={closeModal} type={query?.credits as 'success' | 'cancel'} />
        )}

        {/* Cancel state is here, success is handeled on /subscriptions/success because of GA */}
        {query?.billing === 'cancel' && <AfterSubscriptionModal onClose={closeModal} type={query?.billing} />}
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
